<template>
    <span :class="`badge badge-`+opt.style+(_events.click?' pointer':'')" @click="doClick">{{opt.label}}</span>
</template>

<script>
export default {
    props:{
        type: String,
        data: Object,
        status: String,
    },
    computed:{
        opt(){
            if(this.type=='contact'){
                return {
                    'R': {label:"Read",style:"success"},
                    'U': {label:"Unread",style:"warning"},
                }[this.status]
            }else{
                return {
                    'Y': {label:"Active",style:"success"},
                    'N': {label:"Inactive",style:"warning"},
                }[this.status]
            }
        }
    },
    methods: {
        doClick(){
            this.$emit('click', this)
        }
    },
}
</script>